<template>
  <v-app>
    <div>
      <div class="row align-items-center justify-content-end mt-n3">
        <div class="col-md-8 col-12">
          <button
            class="btn mx-1 btn-timeseries"
            style="
              padding-left: 4px;
              padding-right: 4px;
              padding-top: 5px;
              padding-bottom: 5px;
            "
            @click="btnActiveOnClick('days')"
            v-bind:class="{
              'btn-info': btnActive.days,
              'btn-outline-info': btnActive.days == false,
            }"
          >
            Hari Ini
          </button>
          <button
            class="btn mx-1 btn-timeseries"
            style="
              padding-left: 4px;
              padding-right: 4px;
              padding-top: 5px;
              padding-bottom: 5px;
            "
            @click="btnActiveOnClick('month')"
            v-bind:class="{
              'btn-info': btnActive.month,
              'btn-outline-info': btnActive.month == false,
            }"
          >
            Bulan Ini
          </button>
          <!-- <button
            class="btn mx-1 btn-timeseries"
            style="
              padding-left: 4px;
              padding-right: 4px;
              padding-top: 5px;
              padding-bottom: 5px;
            "
            @click="btnActiveOnClick('lastMonth')"
            v-bind:class="{
              'btn-info': btnActive.lastMonth,
              'btn-outline-info': btnActive.lastMonth == false,
            }"
          >
            Bulan Kemarin
          </button> -->
          <button
            class="btn mx-1 btn-timeseries"
            style="
              padding-left: 4px;
              padding-right: 4px;
              padding-top: 5px;
              padding-bottom: 5px;
            "
            @click="btnActiveOnClick('year')"
            v-bind:class="{
              'btn-info': btnActive.year,
              'btn-outline-info': btnActive.year == false,
            }"
          >
            Tahun Ini
          </button>
          <button
            class="btn mx-1 btn-timeseries"
            style="
              padding-left: 4px;
              padding-right: 4px;
              padding-top: 5px;
              padding-bottom: 5px;
            "
            v-bind:class="{
              'btn-info': btnActive.period,
              'btn-outline-info': btnActive.period == false,
            }"
            @click="btnPeriodeOnClick"
          >
            Periode
          </button>
        </div>
        <div class="col-md-4 col-12">
          <b-input-group v-if="source == 0">
            <b-form-input
              type="text"
              v-model="display.patient_name"
              placeholder="Cari Berdasarkan Pasien"
              readonly
              @click="$bvModal.show('modal-patient')"
            ></b-form-input>
            <template #append>
              <b-button squared @click="pagination" variant="success"
                >Cari</b-button
              >
              <b-button squared @click="resetFilter" variant="danger"
                >Reset</b-button
              >
            </template>
          </b-input-group>
        </div>
      </div>

      <!-- timeseries -->
      <div class="row justify-content-start align-items-center mt-3">
        <div class="col-md-2">
          <span
            v-if="totalRows != null"
            style="width: 150px; color: #245590 !important"
            class="font-weight-bold"
            >{{ totalRows }} Baris Data</span
          >
        </div>
        <div class="col-md-8" v-if="onShowPeriod">
          <span class="d-none d-sm-block">
            <b-input-group>
              <b-form-input
                v-model="filter.start_date"
                type="text"
                placeholder="Awal"
                autocomplete="off"
                readonly
              ></b-form-input>
              <b-input-group-append>
                <b-form-datepicker
                  v-model="filter.start_date"
                  button-only
                  right
                  reset-button
                  locale="id"
                  aria-controls="example-input"
                ></b-form-datepicker>
              </b-input-group-append>
              <b-button squared variant="light">s/d</b-button>
              <b-form-input
                v-model="filter.end_date"
                type="text"
                placeholder="Akhir"
                autocomplete="off"
                readonly
              ></b-form-input>
              <b-input-group-append>
                <b-form-datepicker
                  v-model="filter.end_date"
                  button-only
                  right
                  reset-button
                  locale="id"
                  aria-controls="example-input"
                ></b-form-datepicker>
              </b-input-group-append>
              <template #append>
                <b-button squared @click="pagination" variant="success"
                  >Cari</b-button
                >
                <b-button squared @click="resetFilter" variant="danger"
                  >Reset</b-button
                >
              </template>
            </b-input-group>
          </span>
          <span class="d-block d-sm-none">
            <div class="row">
              <div class="col-12 pb-1">
                <b-input-group>
                  <b-form-input
                    v-model="filter.start_date"
                    type="text"
                    placeholder="Awal"
                    autocomplete="off"
                    readonly
                  ></b-form-input>
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="filter.start_date"
                      button-only
                      right
                      reset-button
                      locale="id"
                      aria-controls="example-input"
                    ></b-form-datepicker>
                  </b-input-group-append>
                </b-input-group>
              </div>
              <div class="col-12 pt-0">
                <b-input-group>
                  <b-button squared variant="light">s/d</b-button>
                  <b-form-input
                    v-model="filter.end_date"
                    type="text"
                    placeholder="Akhir"
                    autocomplete="off"
                    readonly
                  ></b-form-input>
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="filter.end_date"
                      button-only
                      right
                      reset-button
                      locale="id"
                      aria-controls="example-input"
                    ></b-form-datepicker>
                  </b-input-group-append>
                  <template #append>
                    <b-button squared @click="pagination" variant="success"
                      >Cari Periode</b-button
                    >
                    <b-button squared @click="resetFilter" variant="danger"
                      >Reset Periode</b-button
                    >
                  </template>
                </b-input-group>
              </div>
            </div>
          </span>
        </div>
      </div>

      <b-table
        hover
        class="mt-3"
        responsive
        :items="items"
        :fields="fields"
        thead-class="bg-secondary"
      >
        <template #table-colgroup="scope">
          <col
            v-for="field in scope.fields"
            :key="field.key"
            :style="{ width: field.key === 'actions' ? '20%' : '' }"
          />
        </template>
        <!-- detail collapse -->
        <template #cell(expired_at)="data">
          <span
            v-if="Math.abs(data.item.diff_exp_and_today) <= 10"
            class="text-danger font-weight-bold"
            >{{
              data.item.expired_at +
              `(${Math.abs(data.item.diff_exp_and_today)} Hari Lagi)`
            }}</span
          >
          <span
            v-if="
              Math.abs(data.item.diff_exp_and_today) > 10 &&
              Math.abs(data.item.diff_exp_and_today) <= 20
            "
            class="text-warning font-weight-bold"
            >{{
              data.item.expired_at +
              `(${Math.abs(data.item.diff_exp_and_today)} Hari Lagi)`
            }}</span
          >
          <span
            v-if="
              Math.abs(data.item.diff_exp_and_today) > 20 &&
              Math.abs(data.item.diff_exp_and_today) <= 30
            "
            class="text-success font-weight-bold"
            >{{
              data.item.expired_at +
              `(${Math.abs(data.item.diff_exp_and_today)} Hari Lagi)`
            }}</span
          >
        </template>
        <template #cell(price)="data">
          {{ parseInt(data.item.price).toLocaleString("id-ID") }}
        </template>
        <template #cell(is_wa_sent)="data">
          <b-badge pill variant="danger" v-if="data.item.is_wa_sent == 0"
            >Belum Terkirim</b-badge
          >
          <b-badge pill variant="success" v-if="data.item.is_wa_sent == 1"
            >Terkirim</b-badge
          >
        </template>

        <template #row-details="data">
          <div class="card bg-yellow">
            <div class="card-title px-4 py-2 mb-2">
              <span><b>Daftar Item Layanan</b></span>
            </div>
            <div class="card-body px-4 py-2">
              <table class="table">
                <tr>
                  <th>#</th>
                  <th>Nama Layanan</th>
                  <th>Status</th>
                  <th></th>
                </tr>
                <tr v-for="(val, index) in data.item.detail_item" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ val.payment_category_name }}</td>
                  <td>
                    <span>
                      <b>{{ val.usage_total }}</b> dari
                      <b>{{ val.payment_category_quantity }}</b> Terpakai
                    </span>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </template>
        <template #cell(action)="data">
          <b-button
            size="sm"
            class="mr-1 btn-info"
            v-b-tooltip.hover
            title="Info"
            @click="data.toggleDetails"
            ><i class="fas fa-eye px-0"></i
          ></b-button>
          <b-button
            v-if="data.item.is_wa_sent == 0"
            size="sm"
            class="mr-1 btn-success"
            v-b-tooltip.hover
            title="Kirim Whatsapp"
            @click="sendReminder(data.item)"
          >
            <i class="fab fa-whatsapp px-0"></i>
          </b-button>
          <!-- add duration expired in days -->
          <!-- <b-button
            size="sm"
            class="mr-1 btn-warning"
            v-b-tooltip.hover
            title="Perpanjang"
            @click="addDuration(data.item)"
          >
            <i class="fas fa-calendar-day px-0"></i>
          </b-button> -->
        </template>
        <!-- end detail collapse -->
      </b-table>
      <b-pagination
        v-if="items.length != 0"
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        aria-controls="my-table"
        @page-click="pageOnClick"
        class="mt-4"
      ></b-pagination>
    </div>
  </v-app>
</template>

<script>
import validation from "@/core/modules/ValidationModule.js";
import module from "@/core/modules/CrudModule.js";
import ModalPaymentCategories from "@/component/masters/payment-categories/ModalPaymentCategoriesAdd.vue";
import ModalPatient from "@/component/general/ModalPatient.vue";
import moment from "moment";

export default {
  components: {
    ModalPatient,
  },

  props: {
    source: Number,
    // Number :
    // 0 = daftar order
    // 1 = per patient (list patient)
  },

  data() {
    return {
      // Filter
      modal2: false,
      filter: {
        patient_id: "",
        start_date: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
        end_date: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
      },
      // display
      display: {
        patient_name: "",
        service_name: "",
        package_name: "",
        usage_total: "",
        remaining_usage: 0,
      },
      dataDetail: [],
      // Pagination
      perPage: 20,
      currentPage: 1,
      lastCurrentPage: 1,
      totalRows: 0,
      // Table Head
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "expired_at",
          label: "Tanggal Expired",
          sortable: true,
        },
        {
          key: "patient_name",
          label: "Nama Pasien",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama Layanan",
          sortable: true,
        },
        // {
        //   key: "price",
        //   label: "Harga",
        //   sortable: true,
        // },
        {
          key: "is_wa_sent",
          label: "Status",
          sortable: true,
        },
        {
          key: "action",
          label: "",
        },
      ],

      // Table Items
      items: [],
      doctors: [],
      nurses: [],

      // filter timeseries
      onShowPeriod: false,
      btnActive: {
        days: true,
        month: false,
        lastMonth: false,
        year: false,
        period: false,
      },
      lastPeriodType: "days",

      // form
      form: {
        id: "",
        payment_detail_bundle_item_id: "",
        doctor_id: [],
        nurse_id: [],
        notes: "",
        used_date: moment().format("YYYY-MM-DD"),
        status: "",
        used_amount: 1,

        // array string data
        nurses: "",
      },
      error: {
        doctor_id: "",
        nurse_id: "",
        used_date: "",
        notes: "",
        used_amount: "",
      },

      // other data
      payment_category_id: "",
      amountData: [],

      // User Access
      btn: false,
      btnAddDoctorCommission: false,

      // reminder data
      reminder: {
        patient_id: "",
        type: "0",
        number: "",
        item: "",
        start_date: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
        end_date: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
      },
    };
  },

  methods: {
    formaterDate(val) {
      if (val.length == 4) {
        this.form.used_date = this.form.used_date + "-";
      }
      if (val.length == 7) {
        this.form.used_date = this.form.used_date + "-";
      }
    },

    pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      this.lastCurrentPage = page;
      this.pagination();
    },

    formaterDate(val) {
      if (val.length == 4) {
        this.form.used_date = this.form.used_date + "-";
      }
      if (val.length == 7) {
        this.form.used_date = this.form.used_date + "-";
      }
    },

    async getDoctorAndNurseData(id) {
      let doctor = await module.get(
        `package-paid-x-doctor?packagePaid=${id}&displayType=arr`
      );
      this.form.doctor_id = doctor;
      this.form.oldDoctor = doctor;

      let nurse = await module.get(`package-service-paid/detail/${id}`);
      this.form.nurse_id = JSON.parse(nurse.nurses);
    },

    resetFilter() {
      this.filter.patient_id = "";
      this.filter.patient_id = "";
      this.filter.patient_id = "";
      this.display.patient_name = "";
      this.pagination();
    },

    resetFilterPeriod() {
      this.btnActiveOnClick(this.lastPeriodType);
      this.pagination();
    },

    dataAmountVal(usage_total) {
      this.amountData = [];

      for (let i = 1; i <= usage_total; i++) {
        let obj = { label: i, id: i };

        let clone = { ...obj };
        this.amountData.push(clone);
      }
    },

    changeStatus(id, name, usage_total, paymentId) {
      this.form.id = id;
      this.form.payment_detail_bundle_item_id = id;
      this.display.service_name = name;
      this.display.usage_total = usage_total;

      this.dataAmountVal(usage_total);

      // this.payment_category_id = paymentId
      this.getDoctorOptions(paymentId);
      this.$bvModal.show("modal-status");
    },

    btnShowModalDoctor(data, id) {
      this.display.package_name = data;
      this.form.id = id;
      console.log("doctor", id, this.form.id);
      this.$bvModal.show("modal-doctor");
      this.getDoctorAndNurseData(id);
    },

    async detailItem(id, name) {
      let result = await module.get("used-item-service/detail/" + id);
      this.dataDetail = result;
      this.dataDetail.display_date = moment(this.dataDetail.usage_date).format(
        "DD-MM-YYYY"
      );

      console.log(this.dataDetail, "======= bertus ======");

      this.display.service_name = name;
      this.display.remaining_usage = this.dataDetail.payment_usage.length;

      console.log(this.display.remaining_usage);
      this.$bvModal.show("modal-detail");
    },

    async saveDetail() {
      // fill data
      this.form.nurses = JSON.stringify(this.form.nurse_id);

      console.log(this.form, "form");
      this.error.used_amount = "";

      let response = await module.submit(
        this.form,
        "package-service-paid/used-item/add"
      );

      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success

        Swal.fire(response.success.title, response.success.message, "success");

        this.form.notes = "";
        this.form.used_date = moment().format("YYYY-MM-DD");
        this.$bvModal.hide("modal-status");
        this.pagination();
        this.hideModalCancel();

        // set validation null
        this.error.doctor_id = "";
        this.error.used_amount = "";
      }
    },

    hideModalCancel() {
      this.form.id = "";
      this.form.doctor_id = [];
      this.form.nurse_id = [];
      this.form.notes = "";
      this.form.used_date = moment().format("YYYY-MM-DD");
      this.form.status = "";
      this.$bvModal.hide("modal-status");
      this.$bvModal.hide("modal-doctor");
    },

    setPatient(value) {
      this.filter.patient_id = value.id;
      this.display.patient_name = value.name;
      this.$bvModal.hide("modal-patient");
    },

    async pagination() {
      if (this.source == 1) {
        this.filter.patient_id = this.$route.params.id;
      }

      let filterParams = `&patient_id=${this.filter.patient_id}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`;
      let response = await module.paginate(
        "package-service-paid-reminder",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }
      this.items = response.data;
    },

    async getDoctorOptions(id) {
      let route;
      // route = "doctors?payment_category_id="+id;
      route = "doctors";
      let response = await module.setDoctorTreeSelect(route);
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.doctors = response.data;
        this.doctors.unshift({
          label: "Pilih Dokter",
          id: "",
          isDisabled: true,
        });
      }
    },

    async getNurseOptions() {
      let route;
      route = "employees";
      let response = await module.setTreeSelect(route);
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.nurses = response.data;
        this.nurses.unshift({
          label: "Pilih Perawat",
          id: "",
          isDisabled: true,
        });
      }
    },

    // filterByName() {
    //   this.pagination();
    // },

    filterByName() {
      this.currentPage = 1;
      if (this.filter.name == "") {
        this.currentPage = this.lastCurrentPage;
      }
      this.pagination();
    },

    setActiveButton() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);

      let a;
      for (a = 0; a < access_right.length; a++) {
        if (access_right[a] == "6009") {
          this.btn = true;
        }
        if (access_right[a] == "6023") {
          this.btnAddDoctorCommission = true;
        }
      }
    },

    btnPeriodeOnClick() {
      if (this.onShowPeriod == false) {
        this.onShowPeriod = true;
        this.btnActiveOnClick("period");
      } else {
        this.onShowPeriod = false;
        this.btnActiveOnClick("period");
      }
    },

    btnActiveOnClick(type) {
      if (type == "days") {
        // set time
        this.filter.start_date = moment()
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.pagination();

        // set btn Active
        this.btnActive.days = true;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = false;

        this.lastPeriodType = "days";

        // hide period
        this.onShowPeriod = false;
      } else if (type == "month") {
        // set time
        this.filter.start_date = moment()
          .startOf("month")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .endOf("month")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.pagination();

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = true;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = false;

        this.lastPeriodType = "month";

        // hide period
        this.onShowPeriod = false;
      } else if (type == "lastMonth") {
        // set time
        this.filter.start_date = moment()
          .subtract(1, "months")
          .startOf("month")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .subtract(1, "months")
          .endOf("month")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.pagination();

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = true;
        this.btnActive.year = false;
        this.btnActive.period = false;

        this.lastPeriodType = "lastMonth";

        // hide period
        this.onShowPeriod = false;
      } else if (type == "year") {
        // set time
        this.filter.start_date = moment()
          .startOf("year")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .endOf("year")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.pagination();

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = true;
        this.btnActive.period = false;

        this.lastPeriodType = "year";

        // hide period
        this.onShowPeriod = false;
      } else if (type == "period") {
        // set time
        this.filter.start_date = "";
        this.filter.end_date = "";

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = true;
      }
    },

    // send reminder

    // sendReminder(item) {
    //     Swal.fire({
    //     title: "Kirim Reminder ?",
    //     text: "Apakah anda ingin mengirim reminder ke pasien tersebut berisi semua item yang akan expired ?",
    //     icon: "question",
    //     showCancelButton: true,
    //     cancelButtonText:"Tidak,Hanya kirim item ini",
    //     confirmButtonText:"Ya,Kirim semua item"
    //   }).then(async function (result) {
    //     if(result){
    //       this.reminder
    //     }
    //   })
    // },

    async sendReminder(item) {
      this.reminder.patient_id = item.patient_id;
      this.reminder.patient_name = item.patient_name;
      this.reminder.number = item.patient_number;
      this.reminder.item = item;
      this.reminder.start_date = this.filter.start_date;
      this.reminder.end_date = this.filter.end_date;

      //  evt.preventDefault();
      let response = await module.submit(
        this.reminder,
        "payment-categories/reminder"
      );
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire({
          title: "Berhasil",
          text: "Berhasil kirim pesan",
          icon: "success",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.$bvModal.hide("modal-preview-message");
        this.pagination();
      }
      //   console.log(item);
    },
  },

  mounted() {
    // Get Data
    this.pagination();
    this.setActiveButton();
    // this.getDoctorOptions();
    this.getNurseOptions();
  },
};
</script>

<style>
.bg-yellow {
  /* background-color: #fcf8e3; */
  background-color: #fffbf0;
}
</style>